
import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class ScrollToTop extends Vue {
  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
}
